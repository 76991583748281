<style lang="scss">
#f-succes {
  .list-unstyled {
    li {
      label {
        font-size: 14px;
      }
      .detail {
        font-weight: bold;
        margin-left: 5px;
      }
    }
  }
  .sub-header {
    font-size: 16px;
    font-weight: 500;
  }
  .img-outlet-logo {
    width: 90px;
    height: 60px;
  }
}
</style>
<template>
  <section
    class="content d-flex flex-column flex-column-fluid"
    id="f-succes"
    ref="htmlTempForPdf"
  >
    <div class="d-flex flex-column-fluid">
      <div class="container-fluid mb-25">
        <div class="row">
          <div
            class="col-md-4 col-12 offset-md-4 offset-0"
            style="background: white;border: 1px solid rgb(204, 204, 204);padding: 10px;"
          >
            <ul style="display: flex;list-style: none;">
              <li style="width:100%">
                <h5>Service: {{ getfClientRate.title }}</h5>
                <p>We have emailed this information to your email address.</p>
              </li>
              <li style="width:100%">
                <qr-code
                  :text="qrValue"
                  :size="90"
                  bg-color="#eef0f8"
                  error-level="L"
                  style="float:right"
                ></qr-code>
              </li>
            </ul>
            <!-- <div class="row">
               <div class=" col-md-6 col-12">
                  <canvas id="barcode" style="width: inherit; height: 95px"></canvas>
               </div>
             </div> -->
            <hr />
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-9 col-sm-8 col-8">
                    <p class="text-success sub-header">Appointment Details</p>

                    <h4>
                      {{ getfrontAppointmentTime.schedule_date | sDateFormate }}
                    </h4>
                    <h6 class="text-info">
                      {{
                        getfrontAppointmentTime.start_time
                          | timeFormat(getfrontAppointmentTime.schedule_date)
                      }}
                      <!-- -
                      {{
                        getfrontAppointmentTime.end_time
                          | timeFormat(getfrontAppointmentTime.schedule_date)
                      }} -->
                    </h6>
                    <!-- <p class="text-danger" style="font-size: 10px">
                      Please be there  seleted time
                    </p> -->
                  </div>
                  <div
                    class="col-md-3 col-sm-4 col-4"
                    style="border-left: 0.5px solid #ccc"
                  >
                    <div class="text-center" style="margin-top: 15px">
                      <p>Total Amount</p>
                      <h4 class="text-primary">${{ getTotalServiceAmount }}</h4>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-12">
                    <canvas
                      id="barcode"
                      style="width: inherit; height: 75px;padding:0 10px 0 0"
                    ></canvas>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-12">
                    <p class="text-success sub-header">Personal Details</p>
                    <ul class="list-unstyled">
                      <li>
                        <label for="">First Name:</label>
                        <label for="" class="detail">{{
                          getApplicantFirstName
                        }}</label>
                      </li>
                      <li>
                        <label for="">Last Name: </label>
                        <label for="" class="detail">{{
                          getApplicantlastName
                        }}</label>
                      </li>
                      <li>
                        <label for="">Email:</label>
                        <label for="" class="detail">{{
                          getApplicantEmail
                        }}</label>
                      </li>
                      <li>
                        <label for="">Contact No.: </label>
                        <label for="" class="detail">{{
                          getApplicantPhoneNo
                        }}</label>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-6 col-12">
                    <p class="text-success sub-header">Outlet Details</p>
                    <div class="row">
                      <div class="col-12">
                        <ul class="list-unstyled">
                          <li>
                            <label for="">Name:</label
                            ><label for="" class="detail">{{
                              getClientData.name
                            }}</label>
                          </li>
                          <li>
                            <label for="">Address: </label
                            ><label for="" class="detail">{{
                              getClientData.address +
                                ", " +
                                getClientData.zipcode
                            }}</label>
                          </li>
                          <li>
                            <label for=""
                              ><a
                                :href="
                                  'http://www.google.com/maps/place/' +
                                    getClientData.lat +
                                    ',' +
                                    getClientData.long
                                "
                                target="_blank"
                                >Find in the map</a
                              ></label
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-7"></div>
                  <div class="col-12">
                    <button
                      @click="goHome()"
                      class="btn btn-success"
                      style="float: right"
                    >
                      Done
                    </button>
                    <!-- <button
                      class="btn btn-primary pl-5 pr-5"
                      style="float: right; margin-right: 10px"
                      @click="download()"
                    >
                      Download PDF
                    </button> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import VueQRCodeComponent from "vue-qrcode-component";

import { mapGetters } from "vuex";
import moment from "moment";
// import PDF417 from "pdf417-generator";
import * as PDF417 from "pdf417-generator";
import jsPDF from "jspdf";
export default {
  computed: {
    ...mapGetters([
      "getClientData",
      "getfClientName",
      "getfServiceSlug",
      "getTotalServiceAmount",
      "getTotalRateAmount",
      "getfClientRate",
      "getfrontAppointmentTime",
      "getApplicantFirstName",
      "getApplicantMiddleName",
      "getApplicantlastName",
      "getApplicantEmail",
      "getApplicantConfirmEmail",
      "getApplicantPhoneNo",
      "getApplicantAddressLine1",
      "getApplicantAddressLine2",
      "getApplicantZipCode",
      "getApplicantReason",
      "getApplicantCity",
      "getApplicantState",
      "getApplicantWeight",
      "getApplicantSSN",
      "getApplicantPlaceOfBirth",
      "getApplicantcounrtyOfCitizeb",
      "getApplicantGender",
      "getApplicantRace",
      "getApplicantEyeColor",
      "getApplicantHairColor",
      "getApplicantDateOfBirth",
      "getApplicantHeight",
      "getServiceId"
    ])
  },
  components: {
    "qr-code": VueQRCodeComponent
  },
  data() {
    return {
      token: "",
      ap_id: "",
      qrValue: "",
      theValue: ""
    };
  },

  methods: {
    goHome() {
      this.$toastr.s("Please checkyour email for appointment information!");
      this.$router.push({
        name: "frontend.home",
        params: { slug: this.$route.params.client_slug }
      });
    },
    download() {
      var doc = new jsPDF("A4");
      var margins = {
        top: 20,
        bottom: 20,
        left: 20,
        width: 3508
      };

      doc.fromHTML(this.$refs.htmlTempForPdf, margins.left, margins.top, {
        width: margins.width
      });

      doc.save("test.pdf");
    },
    generateBarCode() {
      var canvas = document.getElementById("barcode");
      PDF417.draw(this.theValue, canvas);
    }
  },
  created() {
    this.token = this.$route.query.token;
    this.ap_id = this.$route.query.ap_id;
    var DBB = this.$moment(this.getApplicantDateOfBirth).format("MMDDYYYY");
    var DBC = this.getApplicantGender == "Female" ? 2 : 1;

    var DAU = ((this.getApplicantHeight * 12) / 100).toFixed(2);

    this.theValue =
      "@\n\nANSI 636020090102DL00410279ZC03200010DLDAQ171774992\nDCA\nDCB\nDCD\nDBA\nDCS" +
      this.getApplicantlastName +
      "\nDAC" +
      this.getApplicantFirstName +
      "\nDAD" +
      this.getApplicantMiddleName +
      "\nDBD\nDBB" +
      DBB +
      "\nDBC" +
      DBC +
      "\nDAY" +
      this.getApplicantEyeColor +
      "\nDAU0" +
      DAU * 100 +
      " in\nDAG" +
      this.getApplicantState +
      "\nDAI" +
      this.getApplicantCity +
      "\nDAJ" +
      this.getApplicantAddressLine1 +
      "\nDAK" +
      this.getApplicantZipCode +
      "\nDAQ\nDCF\nDCG\nDDE\nDDF\nDDG";
    this.qrValue =
      window.location.origin +
      "/appointment/view?token=" +
      this.token +
      "&&app_id=" +
      this.ap_id;
    if (this.getApplicantFirstName == "") {
      this.$toastr.e("Token has been expire");
      this.$router.push({
        name: "frontend.home",
        params: { slug: this.$route.params.client_slug }
      });
    }

    setTimeout(() => {
      this.generateBarCode();
    }, 100);
  },

  filters: {
    timeFormat: function(value, date) {
      var fullDate = date + " " + value;
      return moment
        .utc(String(fullDate))
        .local()
        .format("h:mm a");
    },
    toFixedTwoDigit(amount, data) {
      return (
        parseFloat(amount) +
        (data.rate + (data.rate * data.tax_percentage) / 100)
      ).toFixed(2);
    },
    sDateFormate: function(date) {
      return moment
        .utc(String(date))
        .local()
        .format("dddd, MMMM DD,YYYY");
    }
  }
};
</script>
